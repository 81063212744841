<template>
<div>
    <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>

    <div class="row">
        <div class="col-md-12">
            <CCard>
                <CCardBody>
                    <CForm novalidate>
                        <!-- <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Company Name
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput required was-validated />
                            </div>
                        </div> -->

                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Type of user
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    required
                                    was-validated
                                    v-model="category_data.category"
                                />
                                <template v-if="!category_data.category && buttonClick== true">
                                    <p class="errorMessage">User type required</p>
                                </template>
                            </div>
                        </div>

                        <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Graph
                            </label>


                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CRow form class="form-group mt-2">
                                    <CCol sm="12">
                                        <CInputCheckbox
                                            v-for="(
                                                option, optionIndex
                                            ) in sensorList"
                                            :key="optionIndex"
                                            :label="option"
                                            :value="option"
                                            :checked="checkExits(option)"
                                            class="mt-2"
                                            :inline="true"
                                            v-on:input="updateDashBoardData(option)"
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                        </div>

                        <!-- <div class="form-group d-flex mb-0">
                    <label class="asterisk col-md-6 col-lg-6 col-xl-4  mt-2">
                        Graph
                    </label>
                    <div class="col-xl-8 col-lg-6 col-md-6">
                        <CSelect @change="addChip"
                                horizontal
                                :options="options"
                                placeholder="Please select the graphs"
                            />
                    </div>
                </div>

                <div class="form-group d-flex">
                    <CCol class="col-md-6 col-lg-6 col-xl-4"> 
                    </CCol>
                    <div class="row ml-2">
                        <div class="">
                            <div v-for="chip in selectedChip" :key="chip" class="chip mr-2" >
                                {{chip}}
                                <span class="closebtn" @click="removeChip">&times;</span>
                            </div> 
                        </div>
                    </div> 
                </div> -->
                    </CForm>

                    <div class="form-group form-actions mr-3 float-right">
                        <router-link to="/master">
                            <CButton type="submit" size="sm" color="danger">
                                Cancel
                            </CButton>
                        </router-link>
                        <CButton
                            type="submit"
                            size="sm"
                            color="primary"
                            class="ml-3"
                            @click="errorCheck()"
                        >
                            Update
                        </CButton>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const companyCategoryUrl = URL + `${"profile/bank/bashboard/settings/"}`;
const sensorListUrl = URL + `${"profile/sensor/type/"}`;

export default {
    name: "EditTypeofUser",
    // components: { CTableWrapper },
    props: ["item"],

    data() {
        return {
            buttonClick: false,
            category_data: {
                category: "",
                dashboard_data: [],
            },
            sensorList: [],

            errorMessage: "",
            checkError: false,

            checkboxNames: ["Inline Checkboxes"],
        };
    },

    methods: {
        errorCheck(){
            this.buttonClick= true;

            if(this.category_data.category.trim().length!= 0){
                    this.buttonClick= false;
                    this.createCompanyCategory();
               }
        },

        updateDashBoardData(value) {
            if (!this.category_data.dashboard_data.includes(value)) {
                this.category_data.dashboard_data.push(value);
            }
            else {
                this.category_data.dashboard_data = this.category_data.dashboard_data.filter((l) => l !== value);
            }
        },
        checkExits(option){
            if (this.category_data.dashboard_data.includes(option) ) {
                return true
            }
            else {
                return false
            }
        },
        createCompanyCategory() {
            this.axios
                .put(companyCategoryUrl, this.category_data, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id : this.category_data.id },
                })
                .then((response) => {
                    this.$router.push("/master");
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },
        getSensorList() {
            this.axios
                .get(sensorListUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.sensorList = response.data.context;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
    },
    beforeMount() {
        this.getSensorList();
        if (this.item) {
            this.category_data = this.item;
        }
    },

    //   data() {
    //       return {
    //         selectedChip: [],
    //         options: ['Temperature', 'Humidity', 'Moisture', 'NPK ', 'Rainfall', 'Pests affectability',
    //         'Fertigation history', 'Weather'],
    //         horizontal: { label:'col-3', input:'col-9' },
    //     }
    //   },

    //   methods:{
    //       addChip(event){
    //           console.log(event.target.value);
    //           this.selectedChip.push(event.target.value);
    //       },

    //       removeChip(event){
    //           this.selectedChip.splice(this.selectedChip.indexOf(event.target.value), 1);
    //       }
    //   },
};
</script>